
import { Vue, Component } from "vue-property-decorator";
import { ObjectDataSource } from "@/data/Object/ObjecDatatSource";
import {mdiDotsVertical} from "@mdi/js";
import {ListDataSource} from "@/data/List/ListDataSource";

@Component
export default class Home extends Vue {
  dataSource: ObjectDataSource = new ObjectDataSource({
    id: +this.$route.params.id,
    config: {},
    className: "doctor",
  });
  $message: any;

  get caption() {
    return this.dataSource.model?.fullName;
  }

  headers: any = [
    {
      text: "ID",
      value: "id",
      sortable: true,
    },
    {
      text: "Дата создания",
      value: "createDate",
      sortable: true,
    },
    {
      text: "Заголовок",
      value: "caption",
      sortable: true,
    },
    {
      text: "По умолчанию",
      value: "isDefault",
    },
    {
      text: "Статус",
      value: "instanceStateId",
      sortable: false,
    },
  ];

  icons: any = {
    dotsVertical: mdiDotsVertical,
  };

  resumeDataSource: any = new ListDataSource({
    config: {
      pageIndex: 1,
      pageSize: 15,
      filter: JSON.stringify([
        {
          fieldName: "personId",
          fieldValue: +this.$route.params.id,
        },
      ]),
    },
    className: "resume",
  });

  resumeCreateItemModel: any = {
    personId: +this.$route.params.id,
    caption: "",
  };

  routeToPage() {
    this.$router.push({
      name: "resume",
      params: {
        id: this.$route.params.id,
      }
    });
  }

  async setDefault(id: number) {
    await this.dataSource.updateField({
      fieldName: "defaultResumeId",
      fieldValue: id,
    });
    this.$message("Поле успешно обновлено");
  }

  get breadcrumbs() {
    return [
      {
        text: "Главная",
        to: "/",
      },
      {
        text: "Врачи",
        to: "/manage/doctors/",
        exact: true,
      },
      {
        text: this.dataSource.model?.fullName,
        disabled: true,
      },
    ];
  }
}
